@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/

@mixin col($col-break, $col-count, $gutter: margin()) {
	$cont-s: inherit;
	@if $col-break == sp-s {
		$cont-s: map-get($grid-num, sp-s);
	} @else if $col-break == sp {
		$cont-s: map-get($grid-num, sp);
	} @else if $col-break == tb {
		$cont-s: map-get($grid-num, tb);
	} @else if $col-break == pc {
		$cont-s: map-get($grid-num, pc);
	} @else if $col-break == full {
		$cont-s: map-get($grid-num, full);
	}
	$num: $col-count - 1;
	$margin: floor-decimal(calc(($gutter * $num) / $cont-s) * 100%, 2);
	column-gap: floor-decimal(calc($margin / $num), 2);
	& > * {
		flex-basis: floor-decimal(calc((100% - $margin) / $col-count));
	}
}

.l-col4 {
	@include media(tb) {
		@include flex-wrap;
		align-items: stretch;
		@include col(sp, 2, 20);
		row-gap: clamp(2rem, 3vw, 4rem);
	}
	@include media(pc_s) {
		@include col(tb, 2, 48);
	}
	@include media(pc) {
		@include col(pc, 4, 20);
	}
	@include media(sp_less) {
		@include flex-column;
		row-gap: clamp(2rem, 3vw, 4rem);
	}
}

.l-col3 {
	@include media(tb) {
		@include flex-wrap;
		@include col(sp, 2, 20);
		row-gap: clamp(2rem, 3vw, 4rem);
	}
	@include media(pc_s) {
		@include col(tb, 3, 20);
	}
	@include media(pc) {
		@include col(pc, 3, 40);
	}
	@include media(sp_less) {
		@include flex-column;
		row-gap: clamp(2rem, 3vw, 4rem);
	}
}

.l-col2 {
	@include media(tb) {
		@include flex-wrap;
		column-gap: 2.08%;
		row-gap: clamp(2rem, 3vw, 4rem);
		& > * {
			width: 48.96%;
		}
	}
	@include media(pc_s) {
		column-gap: 6%;
		max-width: 80%;
		& > * {
			height: auto;
			width: 47%;
		}
	}
	@include media(sp_less) {
		@include flex-column;
		row-gap: clamp(2rem, 3vw, 4rem);
	}
}
