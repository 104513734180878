@use "../_variables/variables" as *;
@use "break-points" as *;

//	utility
//---------------------------------------------------

@mixin clearfix(){
	&::after{
		display: block;
		content: "";
		clear: both;
	}
}

@mixin l-cont{
	padding: 0 $sp_contmargin;
	@include m-a;
	max-width: $main_w;
	@include media(pc){
		padding: 0 $pc_contmargin;
	}
}
@mixin l-cont2{
	padding: 0 $sp_contmargin;
	margin-left: auto;
	@include m-a;
	max-width: $cont2_w;
	@include media(pc){
		padding: 0 $pc_contmargin;
	}
}

/*	flex
---------------------------------------------------*/
@mixin flex-between {
	display:flex;
	justify-content: space-between;
}

@mixin flex-around {
	display:flex;
	justify-content: space-around;
}

@mixin flex-end {
	display:flex;
	justify-content: flex-end;
}

@mixin flex-column {
	display:flex;
	flex-direction:column;
}
@mixin flex-r-reverse {
	display:flex;
	flex-direction: row-reverse;
}
@mixin flex-c-reverse {
	display:flex;
	flex-direction:column-reverse;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap:wrap;
}


//	padding
//---------------------------------------------------
@mixin cont-pad($pt:3,$pb:4) {
	padding-top:#{$pt}rem;
	padding-bottom:#{$pb}rem;
	@include media(pc_s) {
		padding-top:(#{$pt} * 1.5)rem;
		padding-bottom:(#{$pb} * 1.5)rem;
	}
}
//	dafault_parts
//---------------------------------------------------
@mixin m-a {
	margin-left: auto;
	margin-right:auto;
}

@mixin dec-line {
	text-decoration: underline !important;
}
@mixin dec-none {
	text-decoration: none !important;
}

//	border-radius: 5px;
//---------------------------------------------------
@mixin radius($radius:5) {
	border-radius:#{$radius}px;
}


// 	h3 tit
//---------------------------------------------------
@mixin heading-tit {
	text-align:center;
	line-height: 1.4;
	margin-bottom:calc(2rem + .3em);
	@include f-s_tb(1.5,4);
		@include media(pc_s){
			@include f-s_pc(1.75,8);
		}
		@include media(pc){
			@include fz(36);
	}
	& span {
		display:inline-block;
		position: relative;
		padding-bottom:.35em;
		border-bottom:dotted 2px;
	}
}

