@use "../_mixins/break-points" as *;
@use "../base" as *;
/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
.txt {
	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}
}

/* main */
.main {
	@include clearfix;
	img {
		display: block;
	}
}

/* contents */

.l-cont {
	@include l-cont;
	@include clearfix;
}

.l-box {
	z-index: 1;
	position: relative;
}

.not-found .cont__wrap {
	padding-top: 10rem;
	padding-bottom: 9rem;
	@include media(tb) {
		padding-top: calc(12rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
		padding-bottom: calc(9rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
	}
	@include media(pc) {
		padding-top: 20rem;
		padding-bottom: 16rem;
	}
}

.cont {
	&__wrap {
		padding-top: 9rem;
		padding-bottom: 6rem;
		@include media(tb) {
			padding-top: calc(9rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
			padding-bottom: calc(6rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
		}
		@include media(pc) {
			padding-top: 14rem;
			padding-bottom: 14rem;
		}
	}
	&__inner {
		background: rgba($black, 0.5);
		padding: 3rem;
		color: $o-white;
		@include radius;
		@include media(pc_s) {
			padding: 5rem;
		}
		@include media(sp_less) {
			padding: 3rem 1.5rem;
		}
	}
	&__tit {
		@include f-s_sp(1.8, 12);
		@include f-w(700);
		@include l-sp(3px);
		margin-bottom: 3rem;
		text-align: center;
		font-family: "Zen Kaku Gothic New", "Noto Sans JP", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
		@include media(pc) {
			font-size: 3.2rem;
			margin-bottom: 5rem;
		}
		span {
			display: block;
			font-size: 2.8em;
			@include f-w(500);
			@include l-sp(1px);
			color: $l-gray;
			font-family: "Oswald", sans-serif !important;
			@include media(pc_less) {
				font-size: 2.5em;
			}
		}
		&.tit-01,
		&.tit-03,
		&.tit-05{
			position: relative;
			margin-bottom: 4rem;
			&::before{
				content: '';
				position: absolute;
				bottom: -1.6rem;
				display: inline-block;
				width: 60px;
				height: 5px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: $main_c;
				border-radius: 2px;
			}
		}
		&.tit-qa {
			span{
				color: $white;
			}
		}
	}
}

/* contents box */
.c-box {
	text-align: left;
	@include media(sp_less) {
		@include m-a;
	}
	&__tit {
		@include f-s_sp(1.6, 2);
		@include f-w(600);
		margin-bottom: 0.75rem;
		@include media(pc) {
			font-size: 1.8rem;
		}
		text-align: center;
		&-01{
			margin-bottom: 1rem;
			padding-bottom: 0.6rem;
			border-bottom: 4px solid $point_lgr;
			@include media(pc) {
				font-size: 2rem;
			}
		}
		&-02{
			position: relative;
			margin-bottom: 3rem;
			&::before{
				content: '';
				position: absolute;
				bottom: -1.6rem;
				display: inline-block;
				width: 60px;
				height: 3px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				background-color: $main_c;
				border-radius: 2px;
			}
		}
	}
	&__img {
		position: relative;
		margin-bottom: 1em;
		&__inner {
			@include aspect-img;
		}
	}
	&__txt-02 {
		height: 150px;
		overflow-y: auto;
	}
}
.c-box h4 {
	margin-bottom: 1.5rem;
}
.c-box figcaption {
	font-size: 1.4rem;
	padding: 7px 0 0;
	text-align: center;
}
.c-box p {
	line-height: 1.5;
	padding-bottom: 10px;
	padding-bottom: 1rem;
	text-align: left;
}
.c-box__txt-02 p {
	text-align:justify;
}

.c-box__txt-01 .c-btn,
.c-box__txt-02 .c-btn {
	position: absolute;
	left: 1.5rem;
	bottom: 1.5rem;
	width: calc(100% - 3rem);
}

.frame {
	text-align: left;
	background: rgba($white, 0.8);
	padding: 1.5rem;
	height: 100%;
	display: block;
	color: $txt_c;
	@include radius(3);
	@include dec-none;
}
