/*!------------------------------------*\
    accessibility
\*!------------------------------------*/

/*!
 * Only display content to screen readers
 * See: http://a11yproject.com/posts/how-to-hide-content
 */
.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*!
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * Credit: HTML5 Boilerplate
 */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}
