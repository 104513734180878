@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
	 helper
----------------------------------------------------------------*/
/* display */
.none {
	&-tb {
		@include media(tb) {
			display: none;
		}
	}
	&-pc_s {
		@include media(pc_s) {
			display: none;
		}
	}
	&-pc {
		@include media(pc) {
			display: none;
		}
	}
	&-pc_less {
		@include media(pc_less) {
			display: none;
		}
	}
	&-tb_less {
		@include media(tb_less) {
			display: none;
		}
	}
	&-sp {
		@include media(sp_less) {
			display: none;
		}
	}
}

.block {
	display: block !important;
}
.in-block {
	display: inline-block !important;
}

/* btn */
.btn--main {
	color: $white;
	background-color: $main_c;
	border-color: $main_c;
	&:hover {
		color: $white;
		background-color: $sub_c;
		border-color: $sub_c;
	}
}

.btn--arrow {
	color: $white;
	border-color: $white;
	&:hover {
		color: $white;
		background-color: $sub_c;
		border-color: $sub_c;
	}
}

/* dot-bg */
.dot-bg {
	position: absolute;
	background: #555;
	background-image: radial-gradient(#333 20%, transparent 0), radial-gradient(#333 20%, transparent 0);
	background-position:
		0 0,
		3px 3px;
	background-size: 6px 6px;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.dot-bg {
		position: absolute;
		background: #999;
		background-image: radial-gradient(#000 20%, transparent 0), radial-gradient(#000 20%, transparent 0);
		background-position:
			0 0,
			4px 4px;
		background-size: 8px 8px;
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}
}
.black-bg {
	position: absolute;
	background: #333;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}

/* otherwise */
.p-ab {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 92%;
}

.line--gray {
	content: "";
	width: 100%;
	height: 1px;
	background: #ddd;
}

/* img */
a.light-box {
	@include light-box;
}

a.img-scale {
	@include img-scale;
}

/* ofi */
.ofi-contain {
	@include ofi-contain;
}
.ofi-cover {
	@include ofi-cover;
}
.of-hidden {
	overflow: hidden;
}


/* color */
.cl- {
	&red {
		color: $red;
	}
	&blue {
		color: $blue;
	}
}

/* font-size */
.fs- {
	&s {
		font-size: 0.875em;
	}
	&m {
		font-size: 1.14em;
	}
	&l {
		font-size: 1.25em;
	}
}

/* txt-align */
.txt {
	&-c {
		text-align: center;
		&_tb {
			@include media(tb) {
				text-align: center;
			}
		}
		&_pc {
			@include media(pc) {
				text-align: center;
			}
		}
	}
	&-l {
		text-align: left;
	}
	&-r {
		text-align: right;
	}
}

.clear {
	clear: both;
}
.clearfix {
	@include clearfix;
}

/* margin */
.m {
	&-a {
		margin-left: auto;
		margin-right: auto;
	}
	&t- {
		&s {
			margin-top: 0.6rem;
			@include media(pc_s) {
				margin-top: 1rem;
			}
		}
		&m {
			margin-top: 2rem;
			@include media(pc_s) {
				margin-top: 3rem;
			}
		}
		&l {
			margin-top: 3rem !important;
			@include media(pc_s) {
				margin-top: 5rem !important;
			}
		}
	}
	&r- {
		&s {
			margin-right: 0.6rem;
			@include media(pc_s) {
				margin-right: 1rem;
			}
		}
		&m {
			margin-right: 2rem;
			@include media(pc_s) {
				margin-right: 3rem;
			}
		}
		&l {
			margin-right: 3rem;
			@include media(pc_s) {
				margin-right: 5rem;
			}
		}
	}
	&b- {
		&s {
			margin-bottom: 0.6rem;
			@include media(pc_s) {
				margin-bottom: 1rem;
			}
		}
		&ms {
			margin-bottom: 1rem;
			@include media(pc_s) {
				margin-bottom: 1.6rem;
			}
		}
		&m {
			margin-bottom: 2rem;
			@include media(pc_s) {
				margin-bottom: 3rem;
			}
		}
		&l {
			margin-bottom: 3rem;
			@include media(pc_s) {
				margin-bottom: 5rem;
			}
		}
		&ll {
			margin-bottom: 7rem;
			@include media(pc_s) {
				margin-bottom: 10rem;
			}
		}
	}
	&l- {
		&s {
			margin-left: 0.6rem;
			@include media(pc_s) {
				margin-left: 1rem;
			}
		}
		&m {
			margin-left: 2rem;
			@include media(pc_s) {
				margin-left: 3rem;
			}
		}
		&l {
			margin-left: 3rem;
			@include media(pc_s) {
				margin-left: 5rem;
			}
		}
	}
}
.w {
	&30 {
		@include media(pc_s) {
			width: 30%;
		}
	}
	&50 {
		@include media(pc_s) {
			width: 50%;
		}
	}
	&80 {
		@include media(pc_s) {
			width: 80%;
		}
	}
}
.w-280 {
	@include m-a;
	max-width: 280px;
}

.w-320 {
	@include m-a;
	max-width: 320px;
}
.w-900 {
	@include m-a;
	max-width: 900px;
}
