@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
   loader
----------------------------------------------------------------*/
.loader {
  position: fixed;
  top: calc(50% - 62.5px);
  left: calc(50% - 62.5px);
  display: block;
  &__bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: $white;
    z-index: 998;
    display: block;
  }
}
.circles {
  &__wrap {
    position: relative;
    width: 125px;
    height: 125px;
    margin: auto;
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  }
  &__item {
    position: absolute;
    background-color: $white;
    height: 22px;
    width: 22px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    animation-name: f_fadeG;
    -webkit-animation-name: f_fadeG;
    animation-duration: 0.932s;
    -webkit-animation-duration: 0.932s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-direction: normal;
    -webkit-animation-direction: normal;
  }
}
#circles__item {
  &--01 {
    left: 0;
    top: 51px;
    animation-delay: 0.3495s;
    -webkit-animation-delay: 0.3495s;
  }
  &--02 {
    left: 15px;
    top: 15px;
    animation-delay: 0.466s;
    -webkit-animation-delay:0.466s;
  }
  &--03 {
    left: 51px;
    top: 0;
    animation-delay: 0.5825s;
    -webkit-animation-delay:0.5825s;
  }
  &--04 {
    right: 15px;
    top: 15px;
    animation-delay: 0.699s;
    -webkit-animation-delay:0.699s;
  }
  &--05 {
    right: 0;
    top: 51px;
    animation-delay: 0.8155s;
    -webkit-animation-delay:0.8155s;
  }
  &--06 {
    right: 15px;
    bottom: 15px;
    animation-delay: 0.932s;
    -webkit-animation-delay:0.932s;
  }
  &--07 {
    left: 51px;
    bottom: 0;
    animation-delay: 1.0485s;
    -webkit-animation-delay:1.0485s;
  }
  &--08 {
    left: 15px;
    bottom: 15px;
    animation-delay: 1.165s;
    -webkit-animation-delay:1.165s;
  }
}
@keyframes f_fadeG {
  0% {
    background-color: $main_c;
  }

  100% {
    background-color:$white;
  }
}

@-webkit-keyframes f_fadeG {
  0% {
    background-color: $main_c;
  }

  100% {
    background-color:$white;
  }
}

/*  end loader ======================================= */
