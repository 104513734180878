@use "variables" as *;
/*!------------------------------------*\
    Left
\*!------------------------------------*/
.drawer--left .drawer-nav {
  left: (0 - $drawer-width);
  transition: left $drawer-transitionDuration $drawer-transitionFunction;
}

.drawer--left.drawer-open .drawer-nav,
.drawer--left .drawer-hamburger,
.drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  left: 0;
}

.drawer--left.drawer-open .drawer-hamburger {
  left: $drawer-width;
}
