@use "variables" as *;
@use "../base" as *;

@use "./variables";
@use "./drawerbase";
@use "./position-top";
@use "./position-left";
@use "./position-right";
@use "./hamburger";
@use "./accessibility";
@use "./sidebar";
@use "./navbar";
@use "./dropdown";
@use "./container";

/* global_nav
----------------------------------------------------------------*/
/* グローバルのテキストの色を変更 */
/* sp tel  */
.global__phone {
	&:hover {
		color: $main_c;
		background: $white;
		border: solid 1px $main_c;
		a {
			color: $main_c;
		}
	}
	a {
		color: $white;
		display: block;
	}
}
.global__phone {
	margin: 4rem auto 2rem;
	width: 80px;
	border-radius: 80px;
	-moz-border-radius: 80px;
	-webkit-border-radius: 80px;
	padding: 15px 25px 13px;
	font-size: 30px;
	color: $white;
	background: $main_c;
	border: none;
	@include transition;
	a {
		display: block;
		@include dec-none;
		color: $white;
		display: block;
	}
	&:hover {
		color: $main_c;
		background: $white;
		border: solid 1px $main_c;
		a {
			color: $main_c;
		}
	}
}

.global__items {
	& > li {
		&:not(:last-of-type) {
			@include media(pc_s) {
				margin-right: 3rem;
			}
		}
		a {
			@include media(pc_s) {
				padding: 0.8rem 0;
				color: $txt_c;
				&::before {
					background: $main_c;
					height: 1px;
				}
				&:hover {
					color: $main_c;
				}
			}
			@include media(tb_less) {
				color: $txt_c;
				&:hover {
					color: $main_c;
				}
			}
		}
		.color-header & a {
			color: $white;
			&:hover {
				color: $white;
				&::before {
					background-color: $white;
				}
			}
		}
		.fixed & a {
			color: $txt_c;
			&:hover {
				color: $main_c;
				&::before {
					background-color: $main_c;
				}
			}
		}
	}
}

