@use "../base" as *;

/*--------------------------------------------------------------------/
	format
/--------------------------------------------------------------------*/
* {
	&, &:before, &:after {
		box-sizing: border-box;
	}
}

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/

html{
	font-size: 62.5%;
	height: 100%;
}
body {
	@include f-base;
	@include l-sp;
	@include set_font-family;
	@include line-h;
	@include f-w;
	word-wrap: break-word;
	color: $txt_c;
	background: $white;
	word-wrap: break-word;
	height: 100%;
	-webkit-font-smoothing: antialiased;
}

/*　ドラッグした際の色を変える設定
/* selection
----------------------------------------------------------------*/
body *::selection {
	background: $main_c;
	color: $white;
}

/*Firefoxに対応*/
body *::-moz-selection {
	background: $main_c;
	color: $white;
}


section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}

/* a,img
----------------------------------------------------------------*/
a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: middle;
	background: transparent;
	color: $main_c;
	&:link,&:visited {
		text-decoration: underline;
	}
	&:hover,&:active  {
		text-decoration: none;
		color: $sub_c;
	}
	img {
		display:block;
	}
}

img{
	max-width : 100%;
	vertical-align: middle;
}
a.hover img {
	@include transition;
	&:hover {
		opacity: .7;
	}
}

/* h
----------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-family: "Noto Sans JP", sans-serif;
	line-height: 1.4;
}
h1 {
	letter-spacing: 0;
}

/* 初期状態のwrapper */
#wrapper {
  visibility: hidden;
}