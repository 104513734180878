@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
  	sns navigation
/--------------------------------------------------------------------*/
/* side bar */
$side_icon_size: 32px;
.sns-list__side {
	position: fixed;
	top: 40%;
	right: 0.5rem;
	z-index: 3;
	@include media(pc_less) {
		display: none;
	}
	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	li {
		padding: 0.4rem 0;
		a {
			display: block;
			@include dec-none;
			@include transition;
			position: relative;
			line-height: 1;
			width: $side_icon_size;
			height: $side_icon_size;
			border-radius: 50%;
			background: $b-gray;
			overflow: hidden;
			& i {
				position: absolute;
				color: $white;
				top: 50%;
				left: 52%;
				transform: translate(-50%, -50%);
				font-size: 0.85em;
				@include f-w(100);
				text-align: center;
				vertical-align: middle;
			}
			&:hover {
				transform: scale(1.15, 1.15);
				background: $main_c;
			}
			& .icon-facebook1 {
				font-size: 1.1em;
			}
			& .icon-twitter {
				font-size: 0.7em;
			}
		}
	}
}

/* spnav */
$sp_icon_size: 34px;
.sns-list__spnav {
	margin: 2rem 1.4rem;
	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	li {
		padding: 0 .5rem;
		a {
			display: block;
			@include dec-none;
			@include transition;
			position: relative;
			line-height: 1;
			border-radius: 50%;
			background: $b-gray;
			overflow: hidden;
			width: $sp_icon_size;
			height: $sp_icon_size;
			& i {
				position: absolute;
				@include transition;
				color: $white;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				&.icon-twitter {
					left: 52%;
					font-size: 0.8em;
				}
				&.icon-youtube {
					left: 52%;
				}
			}
			&:hover {
				background: $main_c;
			}
		}
	}
}

/* footer */
$footer_icon_size: 30px;
.sns-footer {
	margin-bottom: 2rem;
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	li {
		padding: 0.5rem 1rem;
		a {
			display: block;
			@include dec-none;
			@include transition;
			position: relative;
			line-height: 1;
			width: $footer_icon_size;
			height: $footer_icon_size;
			& i {
				position: absolute;
				@include transition;
				color: $d-gray;
				top: calc(50% - (#{$footer_icon_size}/ 2));
				left: calc(50% - (#{$footer_icon_size}/ 2));
				font-size: $footer_icon_size;
			}
			&:hover {
				i {
					color: $main_c;
					transform: scale(1.15, 1.15);
				}
			}
		}
	}
}
