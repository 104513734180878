@charset "UTF-8";
/*	flex
---------------------------------------------------*/
/* ofi */
@media print, screen and (min-width: 1920px) {
  /*====================================================================/
  	/*-------------------full PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 1257px) {
  /*====================================================================/
  	/*-------------------PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 977px) {
  /*====================================================================/
  	/*-------------------小さいPCスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 561px) {
  /*====================================================================/
  	/*------------------タブレットスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 376px) {
  /*====================================================================/
  	/*------------------iPhone 以上----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 1256px) {
  /*====================================================================/
  	/*------------------PC以下---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 976px) {
  /*====================================================================/
  	/*------------------スマホタブレット共通スタイル---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  /*====================================================================/
  	/*------------------スマホのみスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  /*====================================================================/
  	/*------------------iPhone 以下----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
/* ========================================
 * foundation
 ======================================= */
/* html5reset-1.6.1.css */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, address,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

address {
  font-style: normal;
}

nav ul {
  list-style: none;
}

ul li {
  list-style: none;
}

/* added */
ol li {
  list-style: none;
}

/* added */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?mc77c");
  src: url("../fonts/icomoon.eot?mc77c#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?mc77c") format("truetype"), url("../fonts/icomoon.woff?mc77c") format("woff"), url("../fonts/icomoon.svg?mc77c#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow1_top:before {
  content: "\e316";
}

.icon-arrow1_right:before {
  content: "\e315";
}

.icon-arrow1_bottom:before {
  content: "\e313";
}

.icon-arrow1_left:before {
  content: "\e314";
}

.icon-home:before {
  content: "\e90b";
}

.icon-phone:before {
  content: "\e909";
}

.icon-mail:before {
  content: "\e911";
}

.icon-right-arrow:before {
  content: "\e907";
}

.icon-up-arrow:before {
  content: "\e903";
}

.icon-pagetop:before {
  content: "\e904";
}

.icon-link:before {
  content: "\e900";
}

.icon-pin:before {
  content: "\e90e";
}

.icon-tag:before {
  content: "\e901";
}

.icon-check:before {
  content: "\e902";
}

.icon-search:before {
  content: "\e905";
}

.icon-search-plus:before {
  content: "\e908";
}

.icon-facebook1:before {
  content: "\e906";
}

.icon-facebook2:before {
  content: "\e90f";
}

.icon-x:before {
  content: "\e90a";
}

.icon-instagram:before {
  content: "\e90c";
}

.icon-line:before {
  content: "\e90d";
}

.icon-youtube:before {
  content: "\e910";
}

/*--------------------------------------------------------------------/
	format
/--------------------------------------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box;
}

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/
html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: 15px;
  letter-spacing: 0.1em;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
  line-height: 1.75;
  font-weight: 400;
  word-wrap: break-word;
  color: #1c1c1c;
  background: #fff;
  word-wrap: break-word;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

/*　ドラッグした際の色を変える設定
/* selection
----------------------------------------------------------------*/
body *::selection {
  background: #0092CA;
  color: #fff;
}

/*Firefoxに対応*/
body *::-moz-selection {
  background: #0092CA;
  color: #fff;
}

section:after,
article:after {
  content: "";
  clear: both;
  display: block;
}

/* a,img
----------------------------------------------------------------*/
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
  color: #0092CA;
}
a:link, a:visited {
  text-decoration: underline;
}
a:hover, a:active {
  text-decoration: none;
  color: #28C4FF;
}
a img {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

a.hover img {
  transition: 0.3s ease all;
}
a.hover img:hover {
  opacity: 0.7;
}

/* h
----------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.4;
}

h1 {
  letter-spacing: 0;
}

/* 初期状態のwrapper */
#wrapper {
  visibility: hidden;
}

/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
/*----------------------------------------------------------------
   loader
----------------------------------------------------------------*/
.loader {
  position: fixed;
  top: calc(50% - 62.5px);
  left: calc(50% - 62.5px);
  display: block;
}
.loader__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 998;
  display: block;
}

.circles__wrap {
  position: relative;
  width: 125px;
  height: 125px;
  margin: auto;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
}
.circles__item {
  position: absolute;
  background-color: #fff;
  height: 22px;
  width: 22px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  animation-name: f_fadeG;
  -webkit-animation-name: f_fadeG;
  animation-duration: 0.932s;
  -webkit-animation-duration: 0.932s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-direction: normal;
  -webkit-animation-direction: normal;
}

#circles__item--01 {
  left: 0;
  top: 51px;
  animation-delay: 0.3495s;
  -webkit-animation-delay: 0.3495s;
}
#circles__item--02 {
  left: 15px;
  top: 15px;
  animation-delay: 0.466s;
  -webkit-animation-delay: 0.466s;
}
#circles__item--03 {
  left: 51px;
  top: 0;
  animation-delay: 0.5825s;
  -webkit-animation-delay: 0.5825s;
}
#circles__item--04 {
  right: 15px;
  top: 15px;
  animation-delay: 0.699s;
  -webkit-animation-delay: 0.699s;
}
#circles__item--05 {
  right: 0;
  top: 51px;
  animation-delay: 0.8155s;
  -webkit-animation-delay: 0.8155s;
}
#circles__item--06 {
  right: 15px;
  bottom: 15px;
  animation-delay: 0.932s;
  -webkit-animation-delay: 0.932s;
}
#circles__item--07 {
  left: 51px;
  bottom: 0;
  animation-delay: 1.0485s;
  -webkit-animation-delay: 1.0485s;
}
#circles__item--08 {
  left: 15px;
  bottom: 15px;
  animation-delay: 1.165s;
  -webkit-animation-delay: 1.165s;
}

@keyframes f_fadeG {
  0% {
    background-color: #0092CA;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes f_fadeG {
  0% {
    background-color: #0092CA;
  }
  100% {
    background-color: #fff;
  }
}
/*  end loader ======================================= */
/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
header {
  position: fixed;
  width: 100%;
  z-index: 10;
  left: 0;
  top: 0;
  padding-top: 1.2rem;
  padding-bottom: 1.5rem;
  transition: all 0.6s;
  margin: 0 auto;
}
@media screen and (max-width: 1256px) {
  header {
    position: fixed;
    width: 100%;
    min-height: 60px;
  }
}
header::after {
  display: block;
  content: "";
  clear: both;
}
header.fixed {
  background: rgba(255, 255, 255, 0.8);
}
@media print, screen and (min-width: 1257px) {
  header.fixed {
    padding-top: 0.7rem;
    padding-bottom: 1rem;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.6s;
  padding: 0 2%;
}

.js-fixed {
  background: rgba(255, 255, 255, 0.8);
}

@media print, screen and (min-width: 977px) {
  .header__logo {
    width: 250px;
  }
}
@media screen and (max-width: 976px) {
  .header__logo {
    max-width: 50%;
    min-width: 30%;
  }
}
.header__logo img {
  width: 100%;
  transition: 0.3s ease all;
}
@media screen and (max-width: 976px) {
  .header__logo img {
    width: 100%;
    max-width: 200px;
  }
}
.header__logo a {
  display: block;
  position: relative;
}

/*--------------------------------------------------------------------/
  	nav global
/--------------------------------------------------------------------*/
#global {
  z-index: 1;
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
  float: right;
}
@media screen and (max-width: 1256px) {
  #global {
    display: none;
  }
}

.global__items {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
}
.global__items > li:not(:last-of-type) {
  margin-right: 0.8rem;
}
.global__items a {
  display: inline-block;
  text-decoration: none !important;
  padding: 0.1rem 0.6rem;
  transition: 0.3s ease all;
  line-height: 1.4;
  font-weight: 500;
  font-size: 1.6rem;
  color: #1c1c1c;
  position: relative;
}
.global__items a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  transition: 0.3s ease all;
  background: #0092CA;
}
.global__items a:hover::before {
  width: 100%;
}

@media screen and (max-width: 560px) and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  #global .global-items > li a {
    height: 100%;
  }
}
@media print, screen and (min-width: 1257px) {
  .drawer-hamburger,
  .global__phone {
    display: none;
  }
}
/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
.txt:not(:last-of-type) {
  margin-bottom: 2rem;
}

/* main */
.main::after {
  display: block;
  content: "";
  clear: both;
}
.main img {
  display: block;
}

/* contents */
.l-cont {
  padding: 0 4%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
}
@media print, screen and (min-width: 1257px) {
  .l-cont {
    padding: 0 20px;
  }
}
.l-cont::after {
  display: block;
  content: "";
  clear: both;
}

.l-box {
  z-index: 1;
  position: relative;
}

.not-found .cont__wrap {
  padding-top: 10rem;
  padding-bottom: 9rem;
}
@media print, screen and (min-width: 561px) {
  .not-found .cont__wrap {
    padding-top: calc(12rem + 50 * (100vw - 560px) / 680);
    padding-bottom: calc(9rem + 50 * (100vw - 560px) / 680);
  }
}
@media print, screen and (min-width: 1257px) {
  .not-found .cont__wrap {
    padding-top: 20rem;
    padding-bottom: 16rem;
  }
}

.cont__wrap {
  padding-top: 9rem;
  padding-bottom: 6rem;
}
@media print, screen and (min-width: 561px) {
  .cont__wrap {
    padding-top: calc(9rem + 50 * (100vw - 560px) / 680);
    padding-bottom: calc(6rem + 50 * (100vw - 560px) / 680);
  }
}
@media print, screen and (min-width: 1257px) {
  .cont__wrap {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
}
.cont__inner {
  background: rgba(0, 0, 0, 0.5);
  padding: 3rem;
  color: #fcfcfc;
  border-radius: 5px;
}
@media print, screen and (min-width: 977px) {
  .cont__inner {
    padding: 5rem;
  }
}
@media screen and (max-width: 560px) {
  .cont__inner {
    padding: 3rem 1.5rem;
  }
}
.cont__tit {
  font-size: calc(1.8rem + 12 * (100vw - 320px) / 680);
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 3rem;
  text-align: center;
  font-family: "Zen Kaku Gothic New", "Noto Sans JP", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif;
}
@media print, screen and (min-width: 1257px) {
  .cont__tit {
    font-size: 3.2rem;
    margin-bottom: 5rem;
  }
}
.cont__tit span {
  display: block;
  font-size: 2.8em;
  font-weight: 500;
  letter-spacing: 1px;
  color: #ddd;
  font-family: "Oswald", sans-serif !important;
}
@media screen and (max-width: 1256px) {
  .cont__tit span {
    font-size: 2.5em;
  }
}
.cont__tit.tit-01, .cont__tit.tit-03, .cont__tit.tit-05 {
  position: relative;
  margin-bottom: 4rem;
}
.cont__tit.tit-01::before, .cont__tit.tit-03::before, .cont__tit.tit-05::before {
  content: "";
  position: absolute;
  bottom: -1.6rem;
  display: inline-block;
  width: 60px;
  height: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #0092CA;
  border-radius: 2px;
}
.cont__tit.tit-qa span {
  color: #fff;
}

/* contents box */
.c-box {
  text-align: left;
}
@media screen and (max-width: 560px) {
  .c-box {
    margin-left: auto;
    margin-right: auto;
  }
}
.c-box__tit {
  font-size: calc(1.6rem + 2 * (100vw - 320px) / 680);
  font-weight: 600;
  margin-bottom: 0.75rem;
  text-align: center;
}
@media print, screen and (min-width: 1257px) {
  .c-box__tit {
    font-size: 1.8rem;
  }
}
.c-box__tit-01 {
  margin-bottom: 1rem;
  padding-bottom: 0.6rem;
  border-bottom: 4px solid #EEEEEE;
}
@media print, screen and (min-width: 1257px) {
  .c-box__tit-01 {
    font-size: 2rem;
  }
}
.c-box__tit-02 {
  position: relative;
  margin-bottom: 3rem;
}
.c-box__tit-02::before {
  content: "";
  position: absolute;
  bottom: -1.6rem;
  display: inline-block;
  width: 60px;
  height: 3px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #0092CA;
  border-radius: 2px;
}
.c-box__img {
  position: relative;
  margin-bottom: 1em;
}
.c-box__img__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.c-box__img__inner::before {
  content: "";
  padding-top: 66.66%;
  position: relative;
  display: block;
}
.c-box__img__inner > div,
.c-box__img__inner figure,
.c-box__img__inner a,
.c-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.c-box__txt-02 {
  height: 150px;
  overflow-y: auto;
}

.c-box h4 {
  margin-bottom: 1.5rem;
}

.c-box figcaption {
  font-size: 1.4rem;
  padding: 7px 0 0;
  text-align: center;
}

.c-box p {
  line-height: 1.5;
  padding-bottom: 10px;
  padding-bottom: 1rem;
  text-align: left;
}

.c-box__txt-02 p {
  text-align: justify;
}

.c-box__txt-01 .c-btn,
.c-box__txt-02 .c-btn {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  width: calc(100% - 3rem);
}

.frame {
  text-align: left;
  background: rgba(255, 255, 255, 0.8);
  padding: 1.5rem;
  height: 100%;
  display: block;
  color: #1c1c1c;
  border-radius: 3px;
  text-decoration: none !important;
}

/*----------------------------------------------------------------
	common-parts
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	heading
/--------------------------------------------------------------------*/
h3.normal {
  text-align: center;
  padding-bottom: 0.75rem;
  margin-bottom: calc(1.5rem + 0.6em);
  position: relative;
  font-weight: 600;
  font-size: calc(1.6rem + 4 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  h3.normal {
    font-size: 2.4rem;
  }
}

h4.normal {
  color: #1c1c1c;
  font-weight: normal;
  border-bottom: 1px dashed #0092CA;
  padding: 0.5rem 0;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: calc(1rem + 2 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  h4.normal {
    font-size: 1.8rem;
  }
}

h5.normal {
  color: #666;
  font-weight: normal;
  padding: 0.5rem 0;
  border-bottom: 1px dashed #bbb;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: calc(0.93rem + 2 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  h5.normal {
    font-size: 1.7rem;
  }
}

h3.blog__tit {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #bbb;
  position: relative;
  font-size: calc(1.6rem + 4 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  h3.blog__tit {
    font-size: 1.8rem;
  }
}
h3.blog__tit::before {
  content: "";
  position: absolute;
  background: #0092CA;
  width: 6rem;
  height: 1px;
  bottom: -1px;
  left: 0;
}

h3.privacy__tit {
  margin-bottom: 1rem;
  font-size: calc(1.6rem + 4 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  h3.privacy__tit {
    font-size: 1.8rem;
  }
}

h3.company__tit {
  font-size: calc(1.8rem + 4 * (100vw - 320px) / 680);
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 1257px) {
  h3.company__tit {
    font-size: 2rem;
  }
}

h3.table__tit {
  font-size: calc(1.8rem + 4 * (100vw - 320px) / 680);
  text-align: left;
  padding: 0.75rem 6rem;
  background: #0092CA;
  position: relative;
  color: #fff;
  font-weight: 500;
  z-index: -1;
}
@media print, screen and (min-width: 1257px) {
  h3.table__tit {
    font-size: 2.2rem;
  }
}
h3.table__tit::before {
  position: absolute;
  content: "";
  left: -2px;
  top: -2px;
  border: none;
  border-left: solid 40px white;
  border-bottom: solid 79px transparent;
  z-index: -2;
}

/*--------------------------------------------------------------------/
	ol
/--------------------------------------------------------------------*/
ol {
  list-style: none;
}

ol.normal {
  margin-left: 1rem;
  line-height: 1.5;
}
ol.normal li {
  list-style: decimal outside;
  margin-left: 15px;
  margin-bottom: 0.5rem;
}

/*--------------------------------------------------------------------/
	ul
/--------------------------------------------------------------------*/
ul {
  list-style: none;
}

ul.normal li {
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 2rem;
}
ul.normal li::before {
  content: "●";
  position: absolute;
  left: 0px;
  top: -1px;
  line-height: 1.5;
}

ul.normal2 li {
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}
ul.normal2 li::before {
  content: "・";
  position: absolute;
  left: 0px;
  top: 0px;
  line-height: 1.5;
}

/*--------------------------------------------------------------------/
	dl
/--------------------------------------------------------------------*/
dl.normal {
  margin-bottom: 1.5rem;
}
dl.normal dt {
  line-height: 1.5;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.1em;
  position: relative;
}
dl.normal dt::before {
  content: "■";
  position: absolute;
  top: 0px;
  left: 0px;
}
dl.normal dd:not(:last-child) {
  margin-bottom: 1.5rem;
}

ul.caution {
  margin-left: 5px;
}
ul.caution li {
  line-height: 1.4;
  padding-left: 1.2rem;
  position: relative;
  color: #df2f2f;
}
ul.caution li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}
ul.caution li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* btn
----------------------------------------------------------------*/
.btn__wrap {
  margin: 0 auto;
  display: block;
}

*[class^=btn-] {
  display: block;
  text-align: center;
  transition: 0.3s ease all;
  -webkit-transition: 0.3s ease;
  position: relative;
  padding: 4px 2rem;
  padding: 1rem 2rem;
  border: 1px solid;
  border-radius: 3pxpx;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none !important;
}

*[class^=btn-]:hover::before {
  right: 0.5rem;
}

.btn--arrow::after {
  font-family: "icomoon";
  content: "\e315";
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

/* table
----------------------------------------------------------------*/
.common__table {
  width: 100%;
  max-width: 960px;
  margin-bottom: 4%;
  margin-left: auto;
  margin-right: auto;
}
.common__table table {
  width: 100%;
}
@media print, screen and (min-width: 561px) {
  .common__table table {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}
@media print, screen and (min-width: 561px) {
  .common__table tr:not(:last-child) {
    border-bottom: 1px solid #bbb;
  }
}
.common__table td {
  width: 100%;
  padding: 0.8rem;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  display: block;
  word-break: break-all;
  width: auto;
  padding-top: 0;
  padding-bottom: 1.5rem;
}
@media print, screen and (min-width: 561px) {
  .common__table td {
    display: table-cell;
    width: auto;
    vertical-align: top;
    text-align: left;
    padding: 1.5rem 1rem;
    border-bottom: none;
  }
}
.common__table td.column-1 {
  font-weight: 600;
  padding-top: 1.5rem;
  padding-bottom: 0;
}
@media print, screen and (min-width: 561px) {
  .common__table td.column-1 {
    width: 30%;
    padding-bottom: 1.5rem;
  }
}
@media print, screen and (min-width: 977px) {
  .common__table td.column-1 {
    width: 25%;
  }
}
@media print, screen and (min-width: 977px) {
  .common__table td.column-2 {
    width: auto;
  }
}

/* table press CSS */
.tablepress td,
.tablepress th {
  padding: none;
}

.tablepress tbody td,
.tablepress tfoot th {
  border-top: none !important;
}

/* animate css
----------------------------------------------------------------*/
@keyframes vertical {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
h3,
h4,
h5 {
  line-height: 1.4;
}

.txt p {
  margin-bottom: 1rem;
}
.txt p:last-child {
  margin-bottom: 0;
}

.alignright {
  float: right;
  margin: 0 0 0.7rem 0.7rem;
}
.alignleft {
  float: left;
  margin: 0.7rem 0.7rem 0 0;
}
.aligncenter {
  display: block;
  margin: 0 auto 0.7rem;
}

/*--------------------------------------------------------------------/
	others
/--------------------------------------------------------------------*/
.swiper-button-prev,
.swiper-button-next {
  position: relative;
}
.swiper-button-prev i,
.swiper-button-next i {
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* category-list */
.category-list {
  margin-bottom: 1.5rem;
  font-size: 0.9em;
  padding: 0.7rem 0;
}
.category-list p {
  display: inline-block;
}
.category-list ul {
  display: inline-block;
  font-size: 0;
}
.category-list ul li {
  display: inline-block;
  margin-bottom: 0.6rem;
  line-height: 1.2;
  font-size: 1.4rem;
}
.category-list ul li:not(:last-child) {
  margin-right: 0.5rem;
}
.category-list ul li a {
  padding: 0.6rem 1.5rem 0.4rem;
  line-height: 1.2;
  transition: 0.3s ease all;
  display: block;
  color: #fff;
  text-decoration: none !important;
}
.category-list ul li a:hover {
  opacity: 0.8;
}
.category-list__black {
  background: #000;
}

.works__read {
  text-align: left;
  margin-bottom: 3rem;
  font-size: calc(1.4rem + 2 * (100vw - 320px) / 680);
}
@media print, screen and (min-width: 1257px) {
  .works__read {
    font-size: 1.6rem;
  }
}

.works-list {
  position: relative;
}
.works-list .tax__icon {
  font-size: 1.3rem;
}
.works-list a {
  position: relative;
  color: #1c1c1c;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s ease all;
}
.works-list a:hover {
  color: #fff;
  background: rgba(102, 102, 102, 0.9);
}
.works-list a:hover .btn--arrow {
  background-color: #28C4FF;
  border-color: #28C4FF;
}
.works-list__inner {
  position: relative;
  height: 100%;
  padding-bottom: 5rem;
}
.works-list__img__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.works-list__img__inner::before {
  content: "";
  padding-top: 66.66%;
  position: relative;
  display: block;
}
.works-list__img__inner > div,
.works-list__img__inner figure,
.works-list__img__inner a,
.works-list__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-list__txt {
  padding-top: 0.5rem;
}
.works-list .btn--arrow {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #0092CA;
  border-color: #0092CA;
}

@media print, screen and (min-width: 1257px) {
  .slider-col3 .works-list2__inner {
    padding: 0.5rem 8%;
  }
}

@media print, screen and (min-width: 1257px) {
  .slider-col4 .works-list2__inner {
    padding: 0.5rem 2%;
  }
}

.works-list2 {
  overflow: hidden;
  position: relative;
}
.works-list2 .tax-list {
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 4%;
  padding-right: 4%;
  z-index: 5;
  line-height: 1.2;
}
.works-list2 .tax__icon {
  font-size: 1.2rem;
  padding: 0.4rem 0.8rem;
  margin-bottom: 0;
}
.works-list2 a {
  color: #fff;
  width: 100%;
  height: 100%;
  display: block;
}
.works-list2 a:hover {
  color: #fff;
}
.works-list2 a:hover .works-list2__box {
  height: 100%;
  padding-top: 2rem;
}
.works-list2 a:hover .works-list2__inner {
  margin-top: 17%;
}
.works-list2__img {
  margin: 0;
  width: 100%;
}
.works-list2__img__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.works-list2__img__inner::before {
  content: "";
  padding-top: 75%;
  position: relative;
  display: block;
}
.works-list2__img__inner > div,
.works-list2__img__inner figure,
.works-list2__img__inner a,
.works-list2__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.works-list2__box {
  position: absolute;
  width: 100%;
  height: 4.5rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.3s ease all;
  padding: 2rem;
  padding-top: 0.7rem;
}
.works-list2__inner {
  position: relative;
  line-height: 1.4;
  transition: 0.3s ease all;
  padding: 0.5rem 4%;
}
.works-list2__tit {
  text-align: center;
  font-size: calc(1.4rem + 6 * (100vw - 320px) / 680);
  font-weight: 400;
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 561px) {
  .works-list2__tit {
    font-size: calc(1.3rem + 3 * (100vw - 320px) / 680);
  }
}
@media print, screen and (min-width: 1257px) {
  .works-list2__tit {
    font-size: 1.5rem;
  }
}
.works-list2__btn {
  text-align: center;
  padding: 0.5rem 0;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.works-list2__btn span {
  position: relative;
  padding: 0.5rem 2%;
  border: 1px solid #fff;
  display: block;
}
.works-list2__btn span::after {
  font-family: "icomoon";
  content: "\e315";
  position: absolute;
  right: 0;
  top: calc(50% - 0.6rem);
}

.tax-list {
  display: block;
}
.tax-list > * {
  display: inline-block;
}

/* works-category */
[class^=tax__icon] {
  padding: 0.5rem 1.25rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  color: #fff;
  background-color: #666;
}

.gallery-img {
  background: #333;
}

/*--------------------------------------------------------------------/
	banner
/--------------------------------------------------------------------*/
/* common-banner
-----------------------------------------------------------------*/
.common-banner {
  background: #fff;
}
.common-banner__wrap {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media print, screen and (min-width: 561px) {
  .common-banner__wrap {
    padding-top: calc(2rem + 10 * (100vw - 560px) / 680);
    padding-bottom: calc(2rem + 10 * (100vw - 560px) / 680);
  }
}
@media print, screen and (min-width: 1257px) {
  .common-banner__wrap {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.common-banner__list {
  display: flex;
  flex-wrap: wrap;
}
.common-banner__list > * {
  height: auto;
  width: 48.96%;
  vertical-align: top;
}
.common-banner__list > *:not(:nth-child(2n)) {
  margin-right: 2%;
}
.common-banner__list > *:not(:nth-last-of-type(-n+2)) {
  margin-bottom: 1rem;
}
@media print, screen and (min-width: 561px) {
  .common-banner__list > * {
    width: 23.44%;
  }
  .common-banner__list > *:not(:nth-child(2n)) {
    margin-right: 0;
  }
  .common-banner__list > *:not(:nth-last-of-type(-n+2)) {
    margin-bottom: 0;
  }
  .common-banner__list > *:not(:nth-child(4n)) {
    margin-right: 2.08%;
  }
  .common-banner__list > *:not(:nth-last-of-type(-n+4)) {
    margin-bottom: 1.25rem;
  }
}
.common-banner__item a {
  display: block;
  border: 1px solid #bbb;
  transition: 0.3s ease all;
}
.common-banner__item a:hover {
  opacity: 0.7;
}
.common-banner__item a img {
  width: 100%;
}

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
  background: #fff;
  padding: 3rem 0 0;
  width: 100%;
  text-align: center;
  position: relative;
}

.footer__inner {
  max-width: 1240px;
  padding: 0 4% 3rem;
}
@media print, screen and (min-width: 977px) {
  .footer__inner {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-bottom: 5rem;
  }
}
@media print, screen and (min-width: 1257px) {
  .footer__inner {
    padding: 0 20px 5rem;
  }
}

.footer__logo {
  margin: 0 auto 1.5rem;
}
@media print, screen and (min-width: 977px) {
  .footer__logo {
    width: 25%;
  }
}
@media screen and (max-width: 976px) {
  .footer__logo {
    width: 50%;
  }
}
@media screen and (max-width: 560px) {
  .footer__logo {
    width: 70%;
  }
}

.footer__copy {
  margin-top: 0.5rem;
  padding: 1.2rem 1.5rem 1.5rem;
  color: #fff;
  background: #0092CA;
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 1px;
  font-size: 1.1rem;
  text-align: center;
}

/* pagetop
--------------------------------------------------------------------*/
#pagetop {
  display: block;
  position: fixed;
  z-index: 10;
}
@media print, screen and (min-width: 977px) {
  #pagetop {
    right: 2rem;
  }
}
@media screen and (max-width: 976px) {
  #pagetop {
    left: 50%;
    margin-left: -20px;
  }
}
#pagetop a {
  width: 40px;
  height: 20px;
  border: 20px solid transparent;
  color: #fff;
  text-decoration: none !important;
  transition: 0.3s ease all;
  content: "";
  display: block;
  border-bottom-color: #0092CA;
}
@media print, screen and (min-width: 977px) {
  #pagetop a {
    height: 30px;
    border: 30px solid transparent;
    border-bottom: 30px solid #0092CA;
  }
}
#pagetop a:hover {
  border-bottom-color: #28C4FF;
}
#pagetop i {
  font-size: 24px;
  margin-left: -12px;
}
@media print, screen and (min-width: 977px) {
  #pagetop i {
    font-size: 36px;
    margin-left: -18px;
  }
}

/* 20210407 追記 フッターロゴリンク追加 */
.footer__logo a {
  display: block;
}
.footer__logo a img {
  margin: auto;
}

/*--------------------------------------------------------------------/
  	sns navigation
/--------------------------------------------------------------------*/
/* side bar */
.sns-list__side {
  position: fixed;
  top: 40%;
  right: 0.5rem;
  z-index: 3;
}
@media screen and (max-width: 1256px) {
  .sns-list__side {
    display: none;
  }
}
.sns-list__side ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sns-list__side li {
  padding: 0.4rem 0;
}
.sns-list__side li a {
  display: block;
  text-decoration: none !important;
  transition: 0.3s ease all;
  position: relative;
  line-height: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #333;
  overflow: hidden;
}
.sns-list__side li a i {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 0.85em;
  font-weight: 100;
  text-align: center;
  vertical-align: middle;
}
.sns-list__side li a:hover {
  transform: scale(1.15, 1.15);
  background: #0092CA;
}
.sns-list__side li a .icon-facebook1 {
  font-size: 1.1em;
}
.sns-list__side li a .icon-twitter {
  font-size: 0.7em;
}

/* spnav */
.sns-list__spnav {
  margin: 2rem 1.4rem;
}
.sns-list__spnav ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.sns-list__spnav li {
  padding: 0 0.5rem;
}
.sns-list__spnav li a {
  display: block;
  text-decoration: none !important;
  transition: 0.3s ease all;
  position: relative;
  line-height: 1;
  border-radius: 50%;
  background: #333;
  overflow: hidden;
  width: 34px;
  height: 34px;
}
.sns-list__spnav li a i {
  position: absolute;
  transition: 0.3s ease all;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sns-list__spnav li a i.icon-twitter {
  left: 52%;
  font-size: 0.8em;
}
.sns-list__spnav li a i.icon-youtube {
  left: 52%;
}
.sns-list__spnav li a:hover {
  background: #0092CA;
}

/* footer */
.sns-footer {
  margin-bottom: 2rem;
}
.sns-footer ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sns-footer li {
  padding: 0.5rem 1rem;
}
.sns-footer li a {
  display: block;
  text-decoration: none !important;
  transition: 0.3s ease all;
  position: relative;
  line-height: 1;
  width: 30px;
  height: 30px;
}
.sns-footer li a i {
  position: absolute;
  transition: 0.3s ease all;
  color: #666;
  top: calc(50% - 30px / 2);
  left: calc(50% - 30px / 2);
  font-size: 30px;
}
.sns-footer li a:hover i {
  color: #0092CA;
  transform: scale(1.15, 1.15);
}

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
@media print, screen and (min-width: 561px) {
  .l-col4 {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    column-gap: 3.57%;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
  .l-col4 > * {
    flex-basis: 48.21%;
  }
}
@media print, screen and (min-width: 977px) {
  .l-col4 {
    column-gap: 5%;
  }
  .l-col4 > * {
    flex-basis: 47.5%;
  }
}
@media print, screen and (min-width: 1257px) {
  .l-col4 {
    column-gap: 1.61%;
  }
  .l-col4 > * {
    flex-basis: 23.79%;
  }
}
@media screen and (max-width: 560px) {
  .l-col4 {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
}

@media print, screen and (min-width: 561px) {
  .l-col3 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.57%;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
  .l-col3 > * {
    flex-basis: 48.21%;
  }
}
@media print, screen and (min-width: 977px) {
  .l-col3 {
    column-gap: 2.08%;
  }
  .l-col3 > * {
    flex-basis: 31.94%;
  }
}
@media print, screen and (min-width: 1257px) {
  .l-col3 {
    column-gap: 3.22%;
  }
  .l-col3 > * {
    flex-basis: 31.18%;
  }
}
@media screen and (max-width: 560px) {
  .l-col3 {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
}

@media print, screen and (min-width: 561px) {
  .l-col2 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.08%;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
  .l-col2 > * {
    width: 48.96%;
  }
}
@media print, screen and (min-width: 977px) {
  .l-col2 {
    column-gap: 6%;
    max-width: 80%;
  }
  .l-col2 > * {
    height: auto;
    width: 47%;
  }
}
@media screen and (max-width: 560px) {
  .l-col2 {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 4rem);
  }
}

/*----------------------------------------------------------------
	 helper
----------------------------------------------------------------*/
/* display */
@media print, screen and (min-width: 561px) {
  .none-tb {
    display: none;
  }
}
@media print, screen and (min-width: 977px) {
  .none-pc_s {
    display: none;
  }
}
@media print, screen and (min-width: 1257px) {
  .none-pc {
    display: none;
  }
}
@media screen and (max-width: 1256px) {
  .none-pc_less {
    display: none;
  }
}
@media screen and (max-width: 976px) {
  .none-tb_less {
    display: none;
  }
}
@media screen and (max-width: 560px) {
  .none-sp {
    display: none;
  }
}

.block {
  display: block !important;
}

.in-block {
  display: inline-block !important;
}

/* btn */
.btn--main {
  color: #fff;
  background-color: #0092CA;
  border-color: #0092CA;
}
.btn--main:hover {
  color: #fff;
  background-color: #28C4FF;
  border-color: #28C4FF;
}

.btn--arrow {
  color: #fff;
  border-color: #fff;
}
.btn--arrow:hover {
  color: #fff;
  background-color: #28C4FF;
  border-color: #28C4FF;
}

/* dot-bg */
.dot-bg {
  position: absolute;
  background: #555;
  background-image: radial-gradient(#333 20%, transparent 0), radial-gradient(#333 20%, transparent 0);
  background-position: 0 0, 3px 3px;
  background-size: 6px 6px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dot-bg {
    position: absolute;
    background: #999;
    background-image: radial-gradient(#000 20%, transparent 0), radial-gradient(#000 20%, transparent 0);
    background-position: 0 0, 4px 4px;
    background-size: 8px 8px;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}
.black-bg {
  position: absolute;
  background: #333;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/* otherwise */
.p-ab {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 92%;
}

.line--gray {
  content: "";
  width: 100%;
  height: 1px;
  background: #ddd;
}

/* img */
a.light-box {
  display: block;
  overflow: hidden;
}
a.light-box::after {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.5rem;
  opacity: 0;
  font-family: "icomoon";
  content: "\f002";
  transition: 0.3s ease all;
  color: #fff;
  z-index: 1;
}
a.light-box img {
  transition: 0.3s ease all;
}
a.light-box:hover img {
  transform: scale(1.1, 1.1);
  filter: brightness(50%);
}
a.light-box:hover::after {
  font-size: 5rem;
  opacity: 1;
}

a.img-scale figure {
  overflow: hidden;
}
a.img-scale img {
  transition: 0.3s ease all;
}
a.img-scale:hover img {
  transform: scale(1.1, 1.1);
  opacity: 0.8;
}

/* ofi */
.ofi-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: "object-fit: contain;";
}

.ofi-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.of-hidden {
  overflow: hidden;
}

/* color */
.cl-red {
  color: #df2f2f;
}
.cl-blue {
  color: #2b74e0;
}

/* font-size */
.fs-s {
  font-size: 0.875em;
}
.fs-m {
  font-size: 1.14em;
}
.fs-l {
  font-size: 1.25em;
}

/* txt-align */
.txt-c {
  text-align: center;
}
@media print, screen and (min-width: 561px) {
  .txt-c_tb {
    text-align: center;
  }
}
@media print, screen and (min-width: 1257px) {
  .txt-c_pc {
    text-align: center;
  }
}
.txt-l {
  text-align: left;
}
.txt-r {
  text-align: right;
}

.clear {
  clear: both;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

/* margin */
.m-a {
  margin-left: auto;
  margin-right: auto;
}
.mt-s {
  margin-top: 0.6rem;
}
@media print, screen and (min-width: 977px) {
  .mt-s {
    margin-top: 1rem;
  }
}
.mt-m {
  margin-top: 2rem;
}
@media print, screen and (min-width: 977px) {
  .mt-m {
    margin-top: 3rem;
  }
}
.mt-l {
  margin-top: 3rem !important;
}
@media print, screen and (min-width: 977px) {
  .mt-l {
    margin-top: 5rem !important;
  }
}
.mr-s {
  margin-right: 0.6rem;
}
@media print, screen and (min-width: 977px) {
  .mr-s {
    margin-right: 1rem;
  }
}
.mr-m {
  margin-right: 2rem;
}
@media print, screen and (min-width: 977px) {
  .mr-m {
    margin-right: 3rem;
  }
}
.mr-l {
  margin-right: 3rem;
}
@media print, screen and (min-width: 977px) {
  .mr-l {
    margin-right: 5rem;
  }
}
.mb-s {
  margin-bottom: 0.6rem;
}
@media print, screen and (min-width: 977px) {
  .mb-s {
    margin-bottom: 1rem;
  }
}
.mb-ms {
  margin-bottom: 1rem;
}
@media print, screen and (min-width: 977px) {
  .mb-ms {
    margin-bottom: 1.6rem;
  }
}
.mb-m {
  margin-bottom: 2rem;
}
@media print, screen and (min-width: 977px) {
  .mb-m {
    margin-bottom: 3rem;
  }
}
.mb-l {
  margin-bottom: 3rem;
}
@media print, screen and (min-width: 977px) {
  .mb-l {
    margin-bottom: 5rem;
  }
}
.mb-ll {
  margin-bottom: 7rem;
}
@media print, screen and (min-width: 977px) {
  .mb-ll {
    margin-bottom: 10rem;
  }
}
.ml-s {
  margin-left: 0.6rem;
}
@media print, screen and (min-width: 977px) {
  .ml-s {
    margin-left: 1rem;
  }
}
.ml-m {
  margin-left: 2rem;
}
@media print, screen and (min-width: 977px) {
  .ml-m {
    margin-left: 3rem;
  }
}
.ml-l {
  margin-left: 3rem;
}
@media print, screen and (min-width: 977px) {
  .ml-l {
    margin-left: 5rem;
  }
}

@media print, screen and (min-width: 977px) {
  .w30 {
    width: 30%;
  }
}
@media print, screen and (min-width: 977px) {
  .w50 {
    width: 50%;
  }
}
@media print, screen and (min-width: 977px) {
  .w80 {
    width: 80%;
  }
}

.w-280 {
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
}

.w-320 {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}

.w-900 {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

/*!------------------------------------*\
		Base
\*!------------------------------------*/
.drawer-open {
  overflow: hidden !important;
}

.drawer-nav {
  position: fixed;
  z-index: 101;
  top: 0;
  overflow: hidden;
  width: 26rem;
  height: 100%;
  color: #222;
  background-color: #fff;
}

.drawer-brand {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 3.75rem;
  display: block;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.drawer-menu li a {
  font-size: 1.4rem;
  display: block;
  padding: 1.4rem 1rem;
  margin: 0 1.4rem;
  text-decoration: none !important;
  text-align: left;
  color: #202020;
  border-bottom: 1px solid #ddd;
  transition: 0.3s ease all;
}
.drawer-menu li a:hover {
  color: #0092CA;
  background-color: transparent;
}

.drawer-menu-item {
  font-size: 1rem;
  display: block;
  padding: 1.2rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! overlay */
.drawer-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-open .drawer-overlay {
  display: block;
}

/*!------------------------------------*\
    Top
\*!------------------------------------*/
.drawer--top .drawer-nav {
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--top.drawer-open .drawer-nav {
  top: 0;
}

.drawer--top .drawer-hamburger,
.drawer--top.drawer-open .drawer-hamburger {
  right: 0;
}

/*!------------------------------------*\
    Left
\*!------------------------------------*/
.drawer--left .drawer-nav {
  left: -26rem;
  transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--left.drawer-open .drawer-nav,
.drawer--left .drawer-hamburger,
.drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  left: 0;
}

.drawer--left.drawer-open .drawer-hamburger {
  left: 26rem;
}

/*!------------------------------------*\
    Right
\*!------------------------------------*/
.drawer--right .drawer-nav {
  right: -26rem;
  transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
  right: 0;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 26rem;
}

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.drawer-hamburger {
  position: fixed;
  z-index: 104;
  top: 0;
  display: block;
  box-sizing: content-box;
  width: 3rem;
  padding: 0;
  padding-top: 18px;
  padding-right: 1.2rem;
  padding-bottom: 30px;
  padding-left: 1.2rem;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: transparent;
}

.drawer-hamburger:hover {
  cursor: pointer;
  background-color: transparent;
}

.drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 10px;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  width: 100%;
  height: 1px;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #222;
}

.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  position: absolute;
  top: -10px;
  left: 0;
  content: " ";
}

.drawer-hamburger-icon:after {
  top: 10px;
}

.drawer-open .drawer-hamburger-icon {
  background-color: transparent !important;
}

.drawer-open .drawer-hamburger-icon:before,
.drawer-open .drawer-hamburger-icon:after {
  top: 0;
}

.drawer-open .drawer-hamburger-icon:before {
  transform: rotate(45deg);
}

.drawer-open .drawer-hamburger-icon:after {
  transform: rotate(-45deg);
}

/*!------------------------------------*\
    accessibility
\*!------------------------------------*/
/*!
 * Only display content to screen readers
 * See: http://a11yproject.com/posts/how-to-hide-content
 */
.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*!
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * Credit: HTML5 Boilerplate
 */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

/*!------------------------------------*\
    Sidebar
\*!------------------------------------*/
.drawer--sidebar {
  background-color: #fff;
}

.drawer--sidebar .drawer-contents {
  background-color: #fff;
}

@media (min-width: 64em) {
  .drawer--sidebar .drawer-hamburger {
    display: none;
    visibility: hidden;
  }
  .drawer--sidebar .drawer-nav {
    display: block;
    transform: none;
    position: fixed;
    width: 16rem;
    height: 100%;
  }
  /*! Left */
  .drawer--sidebar.drawer--left .drawer-nav {
    left: 0;
    border-right: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 16rem;
  }
  /*! Right */
  .drawer--sidebar.drawer--right .drawer-nav {
    right: 0;
    border-left: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 16rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 48rem;
  }
}
@media (min-width: 75em) {
  .drawer--sidebar .drawer-nav {
    width: 26rem;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 26rem;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 26rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 60rem;
  }
}
/*!------------------------------------*\
    Navbar
\*!------------------------------------*/
.drawer--navbarTopGutter {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-navbar-header {
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.drawer-navbar {
  z-index: 102;
  top: 0;
  width: 100%;
}

/*! .drawer-navbar modifier */
.drawer-navbar--fixed {
  position: fixed;
}

.drawer-navbar-header {
  position: relative;
  z-index: 102;
  box-sizing: border-box;
  width: 100%;
  height: 3.75rem;
  padding: 0 1.2rem;
  text-align: center;
}

.drawer-navbar .drawer-brand {
  line-height: 3.75rem;
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: none;
}

.drawer-navbar .drawer-brand:hover {
  background-color: transparent;
}

.drawer-navbar .drawer-nav {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-menu {
  padding-bottom: 7.5rem;
}

@media (min-width: 64em) {
  .drawer-navbar {
    height: 3.75rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }
  .drawer-navbar .drawer-navbar-header {
    position: relative;
    display: block;
    float: left;
    width: auto;
    padding: 0;
    border: 0;
  }
  .drawer-navbar .drawer-menu--right {
    float: right;
  }
  .drawer-navbar .drawer-menu li {
    float: left;
  }
  .drawer-navbar .drawer-menu-item {
    line-height: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .drawer-navbar .drawer-hamburger {
    display: none;
  }
  .drawer-navbar .drawer-nav {
    position: relative;
    left: 0;
    overflow: visible;
    width: auto;
    height: 3.75rem;
    padding-top: 0;
    transform: translate3d(0, 0, 0);
  }
  .drawer-navbar .drawer-menu {
    padding: 0;
  }
  /*! dropdown */
  .drawer-navbar .drawer-dropdown-menu {
    position: absolute;
    width: 26rem;
    border: 1px solid #ddd;
  }
  .drawer-navbar .drawer-dropdown-menu-item {
    padding-left: 1.2rem;
  }
}
/*!------------------------------------*\
    Dropdown
\*!------------------------------------*/
.drawer-dropdown-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.drawer-dropdown-menu > li {
  width: 100%;
  list-style: none;
}

.drawer-dropdown-menu-item {
  line-height: 3.75rem;
  display: block;
  padding: 0;
  padding-right: 1.2rem;
  padding-left: 2.4rem;
  text-decoration: none;
  color: #222;
}

.drawer-dropdown-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! open */
.drawer-dropdown.open > .drawer-dropdown-menu {
  display: block;
}

/*! drawer-caret */
.drawer-dropdown .drawer-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  transition: transform 0.2s ease, opacity 0.2s ease;
  transform: rotate(0deg);
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/*! open */
.drawer-dropdown.open .drawer-caret {
  transform: rotate(180deg);
}

/*!------------------------------------*\
    Container
\*!------------------------------------*/
.drawer-container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 64em) {
  .drawer-container {
    max-width: 60rem;
  }
}
@media (min-width: 75em) {
  .drawer-container {
    max-width: 70rem;
  }
}
/* global_nav
----------------------------------------------------------------*/
/* グローバルのテキストの色を変更 */
/* sp tel  */
.global__phone:hover {
  color: #0092CA;
  background: #fff;
  border: solid 1px #0092CA;
}
.global__phone:hover a {
  color: #0092CA;
}
.global__phone a {
  color: #fff;
  display: block;
}

.global__phone {
  margin: 4rem auto 2rem;
  width: 80px;
  border-radius: 80px;
  -moz-border-radius: 80px;
  -webkit-border-radius: 80px;
  padding: 15px 25px 13px;
  font-size: 30px;
  color: #fff;
  background: #0092CA;
  border: none;
  transition: 0.3s ease all;
}
.global__phone a {
  display: block;
  text-decoration: none !important;
  color: #fff;
  display: block;
}
.global__phone:hover {
  color: #0092CA;
  background: #fff;
  border: solid 1px #0092CA;
}
.global__phone:hover a {
  color: #0092CA;
}

@media print, screen and (min-width: 977px) {
  .global__items > li:not(:last-of-type) {
    margin-right: 3rem;
  }
}
@media print, screen and (min-width: 977px) {
  .global__items > li a {
    padding: 0.8rem 0;
    color: #1c1c1c;
  }
  .global__items > li a::before {
    background: #0092CA;
    height: 1px;
  }
  .global__items > li a:hover {
    color: #0092CA;
  }
}
@media screen and (max-width: 976px) {
  .global__items > li a {
    color: #1c1c1c;
  }
  .global__items > li a:hover {
    color: #0092CA;
  }
}
.color-header .global__items > li a {
  color: #fff;
}
.color-header .global__items > li a:hover {
  color: #fff;
}
.color-header .global__items > li a:hover::before {
  background-color: #fff;
}
.fixed .global__items > li a {
  color: #1c1c1c;
}
.fixed .global__items > li a:hover {
  color: #0092CA;
}
.fixed .global__items > li a:hover::before {
  background-color: #0092CA;
}

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS:
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited).
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar.
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars.
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars.
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  touch-action: pinch-zoom;
  /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  touch-action: auto;
}

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  direction: ltr;
}

.mCSB_container {
  width: auto;
  height: auto;
  /* contains the original content */
  overflow: hidden;
}

/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 16px;
  height: auto;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
  /* minimum dragger height */
  z-index: 1;
  width: 100%;
  height: 30px;
  /* the draggable element */
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
  text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  width: 100%;
  height: 20px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: 16px;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  left: 0;
  width: 30px;
  /* minimum dragger width */
  height: 100%;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  margin-right: 30px;
  margin-bottom: 30px;
  overflow: hidden;
}

.mCSB_container_wrapper > .mCSB_container {
  box-sizing: border-box;
  padding-right: 30px;
  padding-bottom: 30px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/
/*
    ----------------------------------------
    6.1 THEMES
    ----------------------------------------
    */
/* default theme ("light") */
.mCSB_scrollTools {
  -ms-filter: "alpha(opacity=75)";
  filter: "alpha(opacity=75)";
  opacity: 0.75;
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  -ms-filter: "alpha(opacity=0)";
  filter: "alpha(opacity=0)";
  opacity: 0;
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  -ms-filter: "alpha(opacity=100)";
  filter: "alpha(opacity=100)";
  opacity: 1;
}

.mCSB_scrollTools .mCSB_draggerRail {
  -ms-filter: "alpha(opacity=40)";
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=75)";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=85)";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=90)";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -ms-filter: "alpha(opacity=40)";
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  filter: "alpha(opacity=40)";
  opacity: 0.4;
}

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /*
        sprites locations
        light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
        dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
        */
}

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /*
        sprites locations
        light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
        dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
        */
}

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /*
        sprites locations
        light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
        dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
        */
}

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /*
        sprites locations
        light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
        dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
        */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  -ms-filter: "alpha(opacity=75)";
  filter: "alpha(opacity=75)";
  opacity: 0.75;
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  -ms-filter: "alpha(opacity=90)";
  filter: "alpha(opacity=90)";
  opacity: 0.9;
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  border-radius: 1px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  border-radius: 1px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  border-radius: 1px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 1px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  border-radius: 2px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  border-radius: 2px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  border-radius: 2px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 2px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  /* auto-expanded scrollbar */
  width: 16px;
  height: 16px;
  margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  -ms-filter: "alpha(opacity=30)";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  filter: "alpha(opacity=30)";
  opacity: 0.3;
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-y;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-x;
}

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  -ms-filter: "alpha(opacity=30)";
  filter: "alpha(opacity=30)";
  opacity: 1;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  -ms-filter: "alpha(opacity=30)";
  filter: "alpha(opacity=30)";
  opacity: 1;
}

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  border-radius: 7px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 5px;
}

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px;
}

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 12px;
  height: auto;
  margin: 2px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: auto;
  height: 12px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: auto;
  left: 0;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=20)";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=50)";
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
}

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=20)";
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  -ms-filter: "alpha(opacity=50)";
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
}

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  height: auto;
  margin: 3px 5px;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: 6px;
  margin: 5px 3px;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */

/* Preload images */
body:after {
  content: url(../img/close.png) url(../img/loading.gif) url(../img/prev.png) url(../img/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  padding-top: 0.5rem;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* print css
----------------------------------------------------------------*/
@media print {
  header {
    display: none;
  }
  nav#global {
    display: none;
  }
  footer {
    display: none;
  }
}