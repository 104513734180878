@use "../_mixins/break-points" as *;
@use "../base" as *;
/*----------------------------------------------------------------
	common-parts
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	heading
/--------------------------------------------------------------------*/
h3.normal {
	text-align: center;
	padding-bottom: 0.75rem;
	margin-bottom: calc(1.5rem + 0.6em);
	position: relative;
	font-weight: 600;
	@include f-s_sp(1.6, 4);
	@include media(pc) {
		@include fz(24);
	}
}

h4.normal {
	color: $txt_c;
	font-weight: normal;
	border-bottom: 1px dashed $main_c;
	padding: 0.5rem 0;
	margin-bottom: 0.75rem;
	font-weight: 500;
	@include f-s_sp(1, 2);
	@include media(pc) {
		@include fz(18);
	}
}

h5.normal {
	color: $d-gray;
	font-weight: normal;
	padding: 0.5rem 0;
	border-bottom: 1px dashed $gray;
	margin-bottom: 0.75rem;
	font-weight: 500;
	@include f-s_sp(0.93, 2);
	@include media(pc) {
		@include fz(17);
	}
}
h3.blog__tit {
	margin-bottom: 2rem;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid $gray;
	position: relative;
	@include f-s_sp(1.6, 4);
	@include media(pc) {
		@include fz(18);
	}
	&::before {
		content: "";
		position: absolute;
		background: $main_c;
		width: 6rem;
		height: 1px;
		bottom: -1px;
		left: 0;
	}
}
h3.privacy__tit {
	margin-bottom: 1rem;
	@include f-s_sp(1.6, 4);
	@include media(pc) {
		@include fz(18);
	}
}
h3.company__tit {
	@include f-s_sp(1.8, 4);
	padding-bottom: 1rem;
	border-bottom: 1px solid $l-gray;
	margin-bottom: 2rem;
	@include media(pc) {
		@include fz(20);
	}
}
h3.table__tit {
	@include f-s_sp(1.8, 4);
	text-align: left;
	padding: 0.75rem 6rem;
	background: $main_c;
	position: relative;
	color: $white;
	@include f-w(500);
	z-index:-1;
	@include media(pc) {
		@include fz(22);
	}
	&::before{
		position: absolute;
		content: '';
		left: -2px;
		top: -2px;
		border: none;
		border-left: solid 40px white;
		border-bottom: solid 79px transparent;
		z-index:-2
	}
}

/*--------------------------------------------------------------------/
	ol
/--------------------------------------------------------------------*/
ol {
	list-style: none;
}
ol.normal {
	margin-left: 1rem;
	line-height: 1.5;
	li {
		list-style: decimal outside;
		margin-left: 15px;
		margin-bottom: 0.5rem;
	}
}

/*--------------------------------------------------------------------/
	ul
/--------------------------------------------------------------------*/
ul {
	list-style: none;
}
ul.normal {
	li {
		text-align: left;
		line-height: 1.5;
		margin-bottom: 0.5rem;
		position: relative;
		padding-left: 2rem;
		&::before {
			content: "●";
			position: absolute;
			left: 0px;
			top: -1px;
			line-height: 1.5;
		}
	}
}
ul.normal2 {
	li {
		text-align: left;
		line-height: 1.5;
		margin-bottom: 0.5rem;
		position: relative;
		padding-left: 1.5rem;
		&::before {
			content: "・";
			position: absolute;
			left: 0px;
			top: 0px;
			line-height: 1.5;
		}
	}
}

/*--------------------------------------------------------------------/
	dl
/--------------------------------------------------------------------*/
dl.normal {
	margin-bottom: 1.5rem;
	dt {
		line-height: 1.5;
		padding-left: 1.5rem;
		margin-bottom: 1rem;
		font-weight: 700;
		font-size: 1.1em;
		position: relative;
		&::before {
			content: "■";
			position: absolute;
			top: 0px;
			left: 0px;
		}
	}
	dd {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}

ul.caution {
	margin-left: 5px;
	& li {
		line-height: 1.4;
		padding-left: 1.2rem;
		position: relative;
		color: $red;
		&::before {
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}

/* btn
----------------------------------------------------------------*/
.btn__wrap {
	margin: 0 auto;
	display: block;
}
*[class^="btn-"] {
	display: block;
	text-align: center;
	@include transition;
	-webkit-transition: 0.3s ease;
	position: relative;
	padding: 4px 2rem;
	padding: 1rem 2rem;
	border: 1px solid;
	@include radius(3px);
	font-family: "Noto Sans JP", sans-serif;
	font-size: 16px;
	font-size: 1.8rem;
	font-weight: 500;
	@include dec-none;
}
*[class^="btn-"]:hover::before {
	right: 0.5rem;
}
.btn--arrow::after {
	font-family: "icomoon";
	content: "\e315";
	position: absolute;
	right: 1rem;
	top: 0.5rem;
}

/* table
----------------------------------------------------------------*/
.common__table {
	width: 100%;
	max-width: $tb;
	margin-bottom: 4%;
	@include m-a;
	& table {
		width: 100%;
		@include media(tb) {
			display: table;
			@include m-a;
		}
	}
	& tr {
		&:not(:last-child) {
			@include media(tb) {
				border-bottom: 1px solid $gray;
			}
		}
	}
	& td {
		width: 100%;
		padding: 0.8rem;
		text-align: left;
		vertical-align: top;
		font-weight: normal;
		display: block;
		word-break: break-all;
		width: auto;
		padding-top: 0;
		padding-bottom: 1.5rem;
		@include media(tb) {
			display: table-cell;
			width: auto;
			vertical-align: top;
			text-align: left;
			padding: 1.5rem 1rem;
			border-bottom: none;
		}
		&.column-1 {
			@include f-w(600);
			padding-top: 1.5rem;
			padding-bottom: 0;
			@include media(tb) {
				width: 30%;
				padding-bottom: 1.5rem;
			}
			@include media(pc_s) {
				width: 25%;
			}
		}
		&.column-2 {
			@include media(pc_s) {
				width: auto;
			}
		}
	}
}

/* table press CSS */
.tablepress td,
.tablepress th {
	padding: none;
}
.tablepress tbody td,
.tablepress tfoot th {
	border-top: none !important;
}

/* animate css
----------------------------------------------------------------*/
@keyframes vertical {
	0% {
		transform: translateY(-10px);
	}
	100% {
		transform: translateY(0px);
	}
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/

h3,
h4,
h5 {
	line-height: 1.4;
}

.txt {
	& p {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.align {
	&right {
		float: right;
		margin: 0 0 0.7rem 0.7rem;
	}
	&left {
		float: left;
		margin: 0.7rem 0.7rem 0 0;
	}
	&center {
		display: block;
		margin: 0 auto 0.7rem;
	}
}

/*--------------------------------------------------------------------/
	others
/--------------------------------------------------------------------*/

.swiper-button-prev,
.swiper-button-next {
	position: relative;
	i {
		font-size: 2em;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

/* category-list */
.category-list {
	margin-bottom: 1.5rem;
	font-size: 0.9em;
	padding: 0.7rem 0;
	p {
		display: inline-block;
	}
	ul {
		display: inline-block;
		font-size: 0;
		li {
			display: inline-block;
			margin-bottom: 0.6rem;
			line-height: 1.2;
			font-size: 1.4rem;
			&:not(:last-child) {
				margin-right: 0.5rem;
			}
			a {
				padding: 0.6rem 1.5rem 0.4rem;
				line-height: 1.2;
				@include transition;
				display: block;
				color: $white;
				text-decoration: none !important;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	&__black {
		background: $black;
	}
}

.works__read {
	text-align: left;
	margin-bottom: 3rem;
	@include f-s_sp(1.4, 2);
	@include media(pc) {
		font-size: 1.6rem;
	}
}

.works-list {
	position: relative;
	.tax__icon {
		@include fz(13);
	}
	a {
		position: relative;
		color: $txt_c;
		width: 100%;
		height: 100%;
		display: block;
		@include transition;
		&:hover {
			color: $white;
			background: rgba($d-gray, 0.9);
			.btn--arrow {
				background-color: $sub_c;
				border-color: $sub_c;
			}
		}
	}
	&__inner {
		position: relative;
		height: 100%;
		padding-bottom: 5rem;
	}
	&__img {
		&__inner {
			@include aspect-img;
		}
	}
	&__txt {
		padding-top: 0.5rem;
	}
	.btn--arrow {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: $main_c;
		border-color: $main_c;
	}
}

.slider-col3 .works-list2__inner {
	@include media(pc) {
		padding: 0.5rem 8%;
	}
}

.slider-col4 .works-list2__inner {
	@include media(pc) {
		padding: 0.5rem 2%;
	}
}

.works-list2 {
	overflow: hidden;
	position: relative;
	& .tax-list {
		position: absolute;
		vertical-align: top;
		top: 0;
		left: 4%;
		padding-right: 4%;
		z-index: 5;
		line-height: 1.2;
	}
	& .tax__icon {
		@include fz(12);
		padding: 0.4rem 0.8rem;
		margin-bottom: 0;
	}
	a {
		color: $white;
		width: 100%;
		height: 100%;
		display: block;
		&:hover {
			color: $white;
			& .works-list2__box {
				height: 100%;
				padding-top: 2rem;
			}
			& .works-list2__inner {
				margin-top: 17%;
			}
		}
	}
	&__img {
		margin: 0;
		width: 100%;
		&__inner {
			@include aspect-img(4, 3);
		}
	}
	&__box {
		position: absolute;
		width: 100%;
		height: 4.5rem;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: rgba($black, 0.8);
		@include transition;
		padding: 2rem;
		padding-top: 0.7rem;
	}
	&__inner {
		position: relative;
		line-height: 1.4;
		@include transition;
		padding: 0.5rem 4%;
	}
	&__tit {
		text-align: center;
		@include f-s_sp(1.4, 6);
		@include f-w(400);
		margin-bottom: 2rem;
		@include media(tb) {
			@include f-s_sp(1.3, 3);
		}
		@include media(pc) {
			font-size: 1.5rem;
		}
	}
	&__btn {
		text-align: center;
		padding: 0.5rem 0;
		line-height: 1;
		@include m-a;
		width: 100%;
		span {
			position: relative;
			padding: 0.5rem 2%;
			border: 1px solid $white;
			display: block;
			&::after {
				font-family: "icomoon";
				content: "\e315";
				position: absolute;
				right: 0;
				top: calc(50% - 0.6rem);
			}
		}
	}
}

.tax-list {
	display: block;
	& > * {
		display: inline-block;
	}
}

/* works-category */
[class^="tax__icon"] {
	padding: 0.5rem 1.25rem;
	margin-bottom: 0.5rem;
	line-height: 1.2;
	color: $white;
	background-color: $d-gray;
}

.gallery-img {
	background: $b-gray;
}
