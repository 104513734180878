@charset "utf-8";
@use "base";
@use "_foundation/foundation";
/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
@use "_component/loader";  //ローダー
@use "_component/header";  //ヘッダー
@use "_component/nav";     //ナビゲーション
@use "_component/common-contents";//コンテンツ
@use "_component/common-parts";  //汎用 css
@use "_component/common-banner";  //共通バナー
@use "_component/footer";  //フッター
@use "_component/sns";  //SNS
@use "_component/column";  //カラム
@use "_component/helper";  //ヘルパーcss
@use "_drawer/drawer";     //ドロワーメニュ
@use "_plugin/mCustomScrollbar";     //カスタムスクロールバー
@use "_plugin/lightbox";     //lightbox
/* print css
----------------------------------------------------------------*/
@media print {
	header {display:none;}
	nav#global{display: none;}
	footer {display:none;}
}