@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
	background: $white;
	padding: 3rem 0 0;
	width: 100%;
	text-align: center;
	position: relative;
}

.footer__inner {
	max-width: 1240px;
	padding: 0 $sp_contmargin 3rem;
	@include media(pc_s) {
		@include m-a;
		position: relative;
		padding-bottom: 5rem;
	}
	@include media(pc) {
		padding: 0 $pc_contmargin 5rem;
	}
}

.footer__logo {
	margin: 0 auto 1.5rem;
	@include media(pc_s) {
		width: 25%;
	}
	@include media(tb_less) {
		width: 50%;
	}
	@include media(sp_less) {
		width: 70%;
	}
}

.footer__copy {
	margin-top: 0.5rem;
	padding: 1.2rem 1.5rem 1.5rem;
	color: $white;
	background: $main_c;
	font-family: "Noto Sans JP", sans-serif;
	letter-spacing: 1px;
	font-size: 1.1rem;
	text-align: center;
}

/* pagetop
--------------------------------------------------------------------*/
#pagetop {
	display: block;
	position: fixed;
	z-index: 10;
	@include media(pc_s) {
		right: 2rem;
	}
	@include media(tb_less) {
		left: 50%;
		margin-left: -20px;
	}
	a {
		width: 40px;
		height: 20px;
		border: 20px solid transparent;
		color: $white;
		@include dec-none;
		@include transition;
		content: "";
		display: block;
		border-bottom-color: $main_c;
		@include media(pc_s) {
			height: 30px;
			border: 30px solid transparent;
			border-bottom: 30px solid $main_c;
		}
		&:hover {
			border-bottom-color: $sub_c;
		}
	}
	i {
		font-size: 24px;
		margin-left: -12px;
		@include media(pc_s) {
			font-size: 36px;
			margin-left: -18px;
		}
	}
}

/* 20210407 追記 フッターロゴリンク追加 */
.footer__logo {
	a {
		display: block;

		img {
			margin: auto;
		}
	}
}
