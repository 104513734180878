@use '../_variables/contents' as *;

@mixin media($media-width)
{
//min-width＝指定サイズ以上
//max-width＝指定サイズ以下
//fullHD以上の記述
	@if $media-width == pc_l {
			@media print, screen and (min-width: #{$full-pc_size}px) {
				@content;
			}
	}
//PC以上の記述
	@else if $media-width == pc {
			@media print, screen and (min-width:#{($pc_size + 17)}px) {
				@content;
		}
	}
//タブレット以上の記述
	@else if $media-width == pc_s {
		@media print, screen and (min-width: #{($tb_size + 17)}px ) {
			@content;
		}
	}
//スマホ以上の記述
	@else if $media-width == tb {
		@media print,screen and (min-width: #{($sp_size + 1)}px ) {
			@content;
		}
	}
	//iPhoneサイズ以上の記述
	@else if $media-width == sp_s {
		@media print, screen and (min-width: #{($sp-s_size + 1)}px ) {
			@content;
		}
	}
	//PCサイズまでの記述
	@else if $media-width == pc_less {
		@media screen and (max-width: #{($pc_size + 16)}px ) {
			@content;
		}
	}
//タブレットサイズまでの記述
	@else if $media-width == tb_less {
		@media screen and (max-width: #{($tb_size + 16)}px ) {
			@content;
		}
	}
//スマホサイズまでの記述
	@else if $media-width == sp_less {
		@media screen and (max-width: #{($sp_size)}px ) {
			@content;
		}
	}
	//iPhoneサイズ以下の記述
	@else if $media-width == sp_s_less {
		@media screen and (max-width: #{($sp-s_size)}px ) {
			@content;
		}
	}
}



