@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
header {
	position: fixed;
	width: 100%;
	z-index: 10;
	left: 0;
	top: 0;
	padding-top:1.2rem;
	padding-bottom:1.5rem;
	transition: all 0.6s;
	margin: 0 auto;
	@include media(pc_less) {
		position: fixed;
		width: 100%;
		min-height: 60px;
	}
	&::after {
		display: block;
		content: "";
		clear: both;
	}
	&.fixed {
		background: rgba(255, 255, 255, 0.8);
		@include media(pc) {
			padding-top:.7rem;
			padding-bottom:1rem;
		}
	}
}

.header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: all 0.6s;
	padding: 0 2%;
}

.js-fixed {
	background: rgba($white, 0.8);
}

.header__logo {
	@include media(pc_s) {
		width: 250px;
	}
	@include media(tb_less) {
		max-width: 50%;
		min-width: 30%;
	}
	img {
		width: 100%;
		@include transition;
		@include media(tb_less) {
			width: 100%;
			max-width: 200px;
		}
	}
	a {
		display: block;
		position: relative;
	}
}