@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?mc77c');
  src:  url('../fonts/icomoon.eot?mc77c#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?mc77c') format('truetype'),
    url('../fonts/icomoon.woff?mc77c') format('woff'),
    url('../fonts/icomoon.svg?mc77c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow1_top:before {
  content: "\e316";
}
.icon-arrow1_right:before {
  content: "\e315";
}
.icon-arrow1_bottom:before {
  content: "\e313";
}
.icon-arrow1_left:before {
  content: "\e314";
}
.icon-home:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e911";
}
.icon-right-arrow:before {
  content: "\e907";
}
.icon-up-arrow:before {
  content: "\e903";
}
.icon-pagetop:before {
  content: "\e904";
}
.icon-link:before {
  content: "\e900";
}
.icon-pin:before {
  content: "\e90e";
}
.icon-tag:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e905";
}
.icon-search-plus:before {
  content: "\e908";
}
.icon-facebook1:before {
  content: "\e906";
}
.icon-facebook2:before {
  content: "\e90f";
}
.icon-x:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\e90c";
}
.icon-line:before {
  content: "\e90d";
}
.icon-youtube:before {
  content: "\e910";
}
