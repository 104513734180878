@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	banner
/--------------------------------------------------------------------*/
/* common-banner
-----------------------------------------------------------------*/
.common-banner {
	background: $white;
	&__wrap {
		padding-top: 3rem;
		padding-bottom: 3rem;
		@include media(tb) {
			padding-top:calc(2rem + 10 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
			padding-bottom:calc(2rem + 10 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
		}
		@include media(pc) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		& > * {
			height: auto;
			width: 48.96%;
			vertical-align: top;
			&:not(:nth-child(2n)) {
				margin-right: 2.00%;
			}
			&:not(:nth-last-of-type(-n+2)) {
				margin-bottom: 1rem;
			}
			@include media(tb) {
				width: 23.44%;
				&:not(:nth-child(2n)) {
					margin-right: 0;
				}
				&:not(:nth-last-of-type(-n+2)) {
					margin-bottom:0;
				}
				&:not(:nth-child(4n)) {
					margin-right: 2.08%;
				}
				&:not(:nth-last-of-type(-n+4)) {
					margin-bottom: 1.25rem;
				}
			}
		}
	}
	&__item {
		a {
			display: block;
			border:1px solid $gray;
			@include transition;
			&:hover {
				opacity: 0.7;
			}
			& img {
				width: 100%;
			}
		}
	}
}
